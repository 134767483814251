<template>
  <div class="container mt-3">
    <h4 class="mb-3">Profile</h4>
    <form @submit.prevent="saveGuardian" v-if="guardian">
      <div>E-mail: {{ guardian.emailAddress }} ({{ guardian.isVerified ? 'verified' : 'unverified' }})</div>
      <div v-if="guardian.isAccountAdmin">You are the primary guardian on the account.</div>
      <div class="row mt-3">
        <div class="col-lg mb-3">
          <label class="form-label" for="firstName">Given name</label>
          <input class="form-control" type="text" name="firstName" id="firstName" v-model="guardian.firstName" required>
        </div>
        <div class="col-lg mb-3">
          <label class="form-label" for="lastName">Family name</label>
          <input class="form-control" type="text" name="lastName" id="lastName" v-model="guardian.lastName" required>
        </div>
        <div class="col-lg mb-3">
          <label class="form-label" for="phoneNumber">Phone number</label>
          <input class="form-control" type="tel" name="phoneNumber" id="phoneNumber" v-model="guardian.phoneNumber" required>
        </div>
      </div>
      <div class="row mt-3">
        <div class="col-lg-5 mb-3">
          <label class="form-label" for="addressStreet">Street Address</label>
          <input class="form-control" type="text" name="addressStreet" id="addressStreet" v-model="guardian.addressStreet" required>
        </div>
        <div class="col-lg mb-3">
          <label class="form-label" for="addressCity">City</label>
          <input class="form-control" type="text" name="addressCity" id="addressCity" v-model="guardian.addressCity" required>
        </div>
        <div class="col-lg mb-3">
          <label class="form-label" for="addressState">State</label>
          <select class="form-select" name="addressState" id="addressState" v-model="guardian.addressState" required>
            <option value="AL">Alabama</option>
            <option value="AK">Alaska</option>
            <option value="AZ">Arizona</option>
            <option value="AR">Arkansas</option>
            <option value="CA">California</option>
            <option value="CO">Colorado</option>
            <option value="CT">Connecticut</option>
            <option value="DE">Delaware</option>
            <option value="DC">District Of Columbia</option>
            <option value="FL">Florida</option>
            <option value="GA">Georgia</option>
            <option value="HI">Hawaii</option>
            <option value="ID">Idaho</option>
            <option value="IL">Illinois</option>
            <option value="IN">Indiana</option>
            <option value="IA">Iowa</option>
            <option value="KS">Kansas</option>
            <option value="KY">Kentucky</option>
            <option value="LA">Louisiana</option>
            <option value="ME">Maine</option>
            <option value="MD">Maryland</option>
            <option value="MA">Massachusetts</option>
            <option value="MI">Michigan</option>
            <option value="MN">Minnesota</option>
            <option value="MS">Mississippi</option>
            <option value="MO">Missouri</option>
            <option value="MT">Montana</option>
            <option value="NE">Nebraska</option>
            <option value="NV">Nevada</option>
            <option value="NH">New Hampshire</option>
            <option value="NJ">New Jersey</option>
            <option value="NM">New Mexico</option>
            <option value="NY">New York</option>
            <option value="NC">North Carolina</option>
            <option value="ND">North Dakota</option>
            <option value="OH">Ohio</option>
            <option value="OK">Oklahoma</option>
            <option value="OR">Oregon</option>
            <option value="PA">Pennsylvania</option>
            <option value="RI">Rhode Island</option>
            <option value="SC">South Carolina</option>
            <option value="SD">South Dakota</option>
            <option value="TN">Tennessee</option>
            <option value="TX">Texas</option>
            <option value="UT">Utah</option>
            <option value="VT">Vermont</option>
            <option value="VA">Virginia</option>
            <option value="WA">Washington</option>
            <option value="WV">West Virginia</option>
            <option value="WI">Wisconsin</option>
            <option value="WY">Wyoming</option>
          </select>
        </div>
        <div class="col-lg mb-3">
          <label class="form-label" for="addressZip">Postal code</label>
          <input class="form-control" type="number" name="addressZip" id="addressZip" v-model="guardian.addressZip" required>
        </div>
      </div>
      <button type="submit" class="btn btn-primary">Save</button>
    </form>
  </div>
</template>

<script lang="ts">
import Guardian from '@/types/guardian'
import { defineComponent } from 'vue'

export default defineComponent({
  data () {
    return {
      guardian: null as Guardian | null
    }
  },
  async mounted () {
    const guardian = this.$store.getters.guardian as Guardian | null
    if (guardian) {
      const response = await this.$api.tokenLogin(guardian.id, guardian.token)
      if (response.guardian) {
        this.guardian = response.guardian
      }
    }
  },
  methods: {
    saveGuardian () {
      if (!this.guardian) { return }
      this.$api.updateGuardian(this.guardian)
    }
  }
})
</script>
