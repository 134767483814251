
import Guardian from '@/types/guardian'
import { defineComponent } from 'vue'

export default defineComponent({
  data () {
    return {
      guardian: null as Guardian | null
    }
  },
  async mounted () {
    const guardian = this.$store.getters.guardian as Guardian | null
    if (guardian) {
      const response = await this.$api.tokenLogin(guardian.id, guardian.token)
      if (response.guardian) {
        this.guardian = response.guardian
      }
    }
  },
  methods: {
    saveGuardian () {
      if (!this.guardian) { return }
      this.$api.updateGuardian(this.guardian)
    }
  }
})
